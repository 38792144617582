import React, { useEffect } from "react";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Components
import Loading from "../../general/Loading";
import SubDomainSignin from "../../pages/portal/SubDomainSignin";
import { useHistory } from "react-router-dom";

const BaseLayout: React.FC = ({ children }) => {
  const { fetchingUser } = useFetchUser();
  
  return (
      <>
          <SubDomainSignin />
          <Loading loading={fetchingUser} />
          <div className="baseLayout__container">
              <main>
                  <div className="baseLayout__toolbar" />
                  {children}
              </main>
          </div>
      </>
  );
};

export default BaseLayout;
