import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";

// Components
import Nav from "../navigation/nav";
import LandingCard from "../general/LandingCard";
import Footer from "../navigation/footer";

// Assets
import brain from "../../assets/landing/brain.png";
import alz from "../../assets/landing/alz.png";
import ccna from "../../assets/landing/ccna.png";
import cihr from "../../assets/landing/cihr.png";
import ctu from "../../assets/landing/ctu.png";
import { useFetchUser } from "../../hooks/useFetchUser";

const Landing: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { currentAdmin, currentParticipant, fetchingUser } = useFetchUser();

    useEffect(() => {
        // already logged in
        if (!fetchingUser) {
            if (currentParticipant) {
                hist.push("/home");
            } else if (currentAdmin) {
                hist.push("/dashboard/home");
            }
        }
    }, [currentParticipant, currentAdmin, fetchingUser, hist]);

    return (
        <div className="landing">
            <Nav />
            <div className="landing__hero ">
                <div className="landing__hero__container content__spacing">
                    <div className="landing__hero__image nofilterimg">
                        <img src={brain} alt="" />
                    </div>
                    <div className="landing__hero__content">
                        <h2>{t("brainHealthPro")}</h2>
                        <h5>{t("brainHealthProDescription")}</h5>
                    </div>
                </div>
            </div>
            <div className="landing__links">
                <div className="landing__links__container content__spacing">
                    <LandingCard linkTitle={t("register")} text={t("registerText")} to="/register" />
                    <LandingCard linkTitle={t("login")} text={t("loginText")} to="/login" />
                    <LandingCard linkTitle={t("accountHelp")} text={t("accountHelpText")} to="/accountHelp" />
                </div>
            </div>
            <div className="landing__about">
                <div className="landing__about__container content__spacing">
                    <div className="landing__about__title">
                        <h4>{t("aboutUs")}</h4>
                    </div>
                    <div className="landing__about__content">
                        <p>{t("about")}</p>
                    </div>
                    <Grid container>
                        <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                            <img src={alz} style={{ maxWidth: "100%", height: "327px", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                            <img src={ccna} style={{ maxWidth: "100%", height: "327px", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                            <img src={cihr} style={{ maxWidth: "100%", height: "327px", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                            <img src={ctu} style={{ maxWidth: "100%", height: "327px", objectFit: "contain" }} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Landing;
